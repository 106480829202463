<template>
  <div class="tw-relative tw-w-full tw-mt-10 lg:mt-4">
    <div class="lg:tw-text-base tw-mx-6 lg:tw-mx-auto">

    Ich bin der Shipping

    </div>
  </div>
</template>

<script>

export default {
  name: 'Shipping',
}
</script>
